import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import { useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";

const ExportMailingListModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<string>("");

  // Firebase callable function
  const exportMailingList = httpsCallable(functions, "exportMailingList");

  const handleExport = async () => {
    try {
      setIsLoading(true);
      setProgress("Gebruikerslijst ophalen...");

      // Initialize variables for pagination
      const perPage = 1000; // Number of users to fetch per page
      let allUsers: any[] = [];
      let nextPageToken: string | undefined = undefined; // Initialize as undefined
      let pageCount = 0;

      // Keep fetching until there are no more users
      do {
        pageCount++;
        setProgress(`Gebruikerslijst ophalen (pagina ${pageCount})...`);

        // Prepare the request payload
        const requestPayload: { perPage: number; pageToken?: string } = { perPage };
        if (nextPageToken) {
          requestPayload.pageToken = nextPageToken; // Only include pageToken if it's valid
        }

        // Call the Firebase function with pagination parameters
        const result = await exportMailingList(requestPayload);

        const responseData = result.data as { users: any[]; nextPageToken?: string };
        const users = responseData.users || [];
        nextPageToken = responseData.nextPageToken; // Update nextPageToken

        // If no users returned, break out of the loop
        if (users.length === 0) {
          break;
        }

        // Add the users to our collection
        allUsers = allUsers.concat(users);

      } while (nextPageToken); // Continue while nextPageToken is defined

      if (allUsers.length > 0) {
        setProgress("CSV bestand genereren...");

        // Remove duplicates based on email
        const uniqueUsers = removeDuplicates(allUsers, "email");

        // Generate CSV content
        const csvContent = generateCsv(uniqueUsers);

        // Trigger download
        downloadCsv(csvContent, "mailing_list.csv");

        setAlert({
          message: `${uniqueUsers.length} gebruikers geëxporteerd naar mailing_list.csv`,
          type: "success",
        });
      } else {
        setAlert({ message: "Geen gebruikers gevonden", type: "warning" });
      }
    } catch (error) {
      console.error("Error exporting mailing list:", error);
      setAlert({
        message: "Er is een fout opgetreden bij het exporteren van de mailinglijst",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setProgress("");
    }
  };

  // Function to remove duplicates based on a key
  const removeDuplicates = (array: any[], key: string) => {
    const seen = new Set();
    return array.filter(item => {
      const value = item[key];
      if (value && !seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  };

  const generateCsv = (users: any[]): string => {
    // Create CSV header
    let csvContent = "username;email\n";

    users.forEach(user => {
      const username = user.username || "";
      const email = user.email || "";

      const escapedUsername = username.includes('"')
        ? `"${username.replace(/"/g, '""')}"`
        : username.includes(';')
          ? `"${username}"`
          : username;

      const escapedEmail = email.includes('"')
        ? `"${email.replace(/"/g, '""')}"`
        : email.includes(';')
          ? `"${email}"`
          : email;

      csvContent += `${escapedUsername};${escapedEmail}\n`;
    });

    return csvContent;
  };


  const downloadCsv = (csvContent: string, filename: string) => {
    // Add UTF-8 BOM to help with Excel recognition
    const BOM = "\uFEFF";
    const csvWithBOM = BOM + csvContent;

    // Create a blob with the CSV content
    const blob = new Blob([csvWithBOM], { type: "text/csv;charset=utf-8;" });

    // Create a download link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    // Add to document, trigger download, and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose} disabled={isLoading}>ANNULEER</Button>
          <Button flatButton onClick={handleExport} disabled={isLoading}>
            {isLoading ? progress || "BEZIG MET EXPORTEREN..." : "DOWNLOAD MAILINGLIJST"}
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <h2>Mailinglijst Exporteren</h2>
      <p>Klik op de download knop om een CSV bestand van alle gebruikers te exporteren.</p>
      <p>De gebruikerslijst bevat de gebruikersnaam en e-mailadres van alle gebruikers.</p>

      {isLoading && (
        <div className="progress-container">
          <p className="progress-text">{progress}</p>
        </div>
      )}
    </Modal>
  );
};

export default ExportMailingListModal;