import './styles.css';
import Header from "../Header";
import ConversationSDK from "../ConversationSDK";
import { useEffect, useState } from "react";
import { ActiveChannelInterface } from "../../interfaces/activeChannelInterface";
import { Call, CometChat } from "@cometchat/chat-sdk-javascript";
import {
  acceptFriendRequest,
  notifyMultipleUsersReload,
  removeFriend,
  sendFriendRequest
} from "../ConversationSDK/friendRequests";
import { FriendRequest } from "../../utils/class/FriendRequest";
import { useNavigate } from "react-router-dom";
import { NotificationInterface } from "../../interfaces/notificationInterface";
import { User } from "firebase/auth";
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import { Functions, httpsCallable } from 'firebase/functions';
import LoadingPage from './LoadingPage';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import { checkUserHasSubscription } from '../../utils/checkSubscription';

export const Chat = ({
  loggedInUser,
  isLoading,
  userHas2FA,

  metrics,

  unreadCount,
  setUnreadCount,
  conversations,
  setConversations,
  initialConversation,
  setInitialConversation,
  loadConversations,
  activeTabIndex,
  setActiveTabIndex,
  notifications,
  setNotifications,
  channels,
  loadIncomingFriendRequests,
  incomingFriendRequests,
  loadOutgoingFriendRequests,
  outgoingFriendRequests,
  blockedUsers,
  fetchBlockedUsers,
  friends,
  loadFriends,
  setFriends,
  handleLogout,

  currentChat,
  setCurrentChat,
  chatWithUser,
  setChatWithUser,
  messages,
  setMessages,
  members,
  setMembers,

  setAlert,
  userSettings,
  setUserSettings,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,
  functions,
  payFunctions,

  handleChangeGroupConversation,

  activeChannels,
  setActiveChannels,
  onlineMembersCount,

  handleChangeChannel,
  fetchGroupMembers,

  setWarningMessage,
  user,

  ongoingCall,
  setOngoingCall,
  outgoingCall,
  setOutgoingCall,
  handleInitiateCall,
  handleTerminateCall,

  needSubscriptionWarning,
  setNeedSubscriptionWarning,
}: {
  loggedInUser: { firebaseUser: User | null, cometChatUser: CometChat.User | null } | null,
  isLoading: boolean;
  userHas2FA: boolean;

  metrics: { messageMetrics: number, concurrentUsers: number };

  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>;
  conversations: ConversationInterface[];
  setConversations: React.Dispatch<React.SetStateAction<ConversationInterface[]>>;
  initialConversation: CometChat.Conversation | null;
  setInitialConversation: React.Dispatch<React.SetStateAction<CometChat.Conversation | null>>;
  loadConversations: (user: CometChat.User) => void;
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  notifications: NotificationInterface[] | null;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  channels: CometChat.Group[];
  loadIncomingFriendRequests: () => void;
  incomingFriendRequests: FriendRequest[];
  loadOutgoingFriendRequests: () => void;
  outgoingFriendRequests: FriendRequest[];
  blockedUsers: CometChat.User[];
  fetchBlockedUsers: () => void;
  friends: CometChat.User[];
  loadFriends: () => void;
  setFriends: React.Dispatch<React.SetStateAction<CometChat.User[]>>;
  handleLogout: () => void;

  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  chatWithUser: CometChat.User | undefined;
  setChatWithUser: React.Dispatch<React.SetStateAction<CometChat.User | undefined>>;
  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  members: CometChat.GroupMember[];
  setMembers: React.Dispatch<React.SetStateAction<CometChat.GroupMember[]>>;

  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
  userSettings: UserSettingsInterface;
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettingsInterface>>;

  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;
  functions: Functions;
  payFunctions: Functions;

  handleChangeGroupConversation: (group: CometChat.Group) => Promise<void>;

  activeChannels: ActiveChannelInterface[];
  setActiveChannels: React.Dispatch<React.SetStateAction<ActiveChannelInterface[]>>;
  onlineMembersCount: { [key: string]: number } | null;

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  fetchGroupMembers: (currentChat: ActiveChannelInterface) => void;

  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  user: User | null;

  ongoingCall: Call | null;
  setOngoingCall: React.Dispatch<React.SetStateAction<Call | null>>;
  outgoingCall: { call: Call, receiver: CometChat.User } | null;
  setOutgoingCall: React.Dispatch<React.SetStateAction<{ call: Call, receiver: CometChat.User } | null>>;
  handleInitiateCall: (user: CometChat.User) => void;
  handleTerminateCall: () => void;

  needSubscriptionWarning: React.ReactNode | null;
  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {
  const loggedInCometChatUser = loggedInUser?.cometChatUser;
  const navigate = useNavigate();

  const [usersTyping, setUsersTyping] = useState<{ name: string, typingStarted: number }[] | null>(null);

  useEffect(() => {
    if (userHas2FA && !loggedInUser?.cometChatUser) {
      navigate("/2fa");
    }
  }, [userHas2FA, loggedInUser, navigate]);

  const handleChangeConversation = (conversation: CometChat.Conversation) => {
    const conversationWith = conversation.getConversationWith();
    const isGroup = conversation.getConversationType() === 'group';

    let receiverId;
    let guid;
    if (!isGroup && 'getUid' in conversationWith) {
      receiverId = conversationWith.getUid();
    } else if (isGroup && 'getGuid' in conversationWith) {
      guid = conversationWith.getGuid();
    }

    const newConversation = {
      id: guid ? guid : conversation.getConversationId(),
      receiverId: receiverId,
      icon: '',
      name: conversationWith.getName(),
      joinedAt: Date.now(),
      isGroup: isGroup,
      conversation,
    };

    setCurrentChat(newConversation);
    const lastMsg = conversation.getLastMessage();

    try {
      CometChat.markAsRead(
        lastMsg.getId(),
        lastMsg.getSender().getUid(),
        'user',
        lastMsg.getSender().getUid()
      ).then(
        () => {
          conversation.setUnreadMessageCount(0);
          const { [conversation.getConversationId()]: _, ...rest } = unreadCount;

          setUnreadCount(rest);

          setActiveTabIndex(1);
        },
        (error: any) => {
          console.error('Messages marking as read failed with error:', error);
        }
      );
    } catch (error) {
      console.error('Error processing last message:', error);
    }
  };

  const refreshConversations = () => {
    if (!loggedInCometChatUser) return;
    loadConversations(loggedInCometChatUser);
  };

  const deleteGroup = (conversation: any) => {
    // Get members of the group
    const guid = conversation.guid;
    let members: string[] = [];

    const groupMembersRequest = new CometChat.GroupMembersRequestBuilder(guid).setLimit(100).build();

    groupMembersRequest.fetchNext().then(
      groupMembers => {
        members = groupMembers.map(member => member.getUid());
      },
      error => {
        console.error("Group member list fetching failed with error:", error);
      }
    );

    CometChat.deleteGroup(guid).then(
      () => {
        if (conversation.guid === currentChat.id) {
          setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
        }
        setAlert({ message: 'Groep verwijderd', type: 'success' });
        notifyMultipleUsersReload(functions, members, 'group_deleted', conversation); // Notify all users to reload their group list
        refreshConversations();
      },
      error => {
        console.error("Failed to delete group:", error);
        setAlert({ message: 'Er is iets fout gegaan bij het verwijderen van de groep', type: 'error' });
      }
    );
  };

  const leaveGroup = async (conversation: any) => {
    try {
      await CometChat.leaveGroup(conversation.guid);

      if (conversation.guid === currentChat.id) {
        setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      }

      setAlert({ message: 'Je hebt de groep verlaten', type: 'success' });
      refreshConversations();
    } catch (error) {
      console.error("Failed to leave group:", error);
      setAlert({ message: 'Er is iets fout gegaan bij het verlaten van de groep', type: 'error' });
    }
  };

  const handleLeaveGroup = (conversation: any) => {
    if (!loggedInUser?.cometChatUser) return;
    const isGroupOwner = conversation.owner === loggedInUser.cometChatUser.getUid();

    if (isGroupOwner) deleteGroup(conversation);
    else leaveGroup(conversation);
  }

  const deleteConversation = async (conversation: any) => {
    if (!loggedInUser?.cometChatUser) return;

    try {
      // Set all messages as read
      const lastMessageId = conversation.lastMessage?.id;
      const senderId = conversation.conversationWith.uid;
      const receiverType = conversation.conversationType;

      if (lastMessageId) await CometChat.markAsRead(lastMessageId, loggedInUser.cometChatUser.getUid(), receiverType, senderId);

      // Remove the conversation from the unread count
      setUnreadCount(prevState => {
        const newState = { ...prevState };
        delete newState[conversation.conversationId];
        return newState;
      });

      // Delete the conversation
      await CometChat.deleteConversation(senderId, receiverType)
        .catch(error => {
          if (error.code !== 'ERR_CONVERSATION_NOT_ACCESSIBLE') {
            throw error;
          }
        })

      if (conversation.conversationId === currentChat.id) {
        setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      }

      setAlert({ message: 'Gesprek verwijderd', type: 'success' });
      refreshConversations();
    } catch (error) {
      console.error("Error marking messages as read or deleting conversation:", error);
      setAlert({ message: 'Er is iets fout gegaan bij het verwijderen van het gesprek', type: 'error' });
    }
  };

  // This function is called when the user clicks the delete conversation button
  const handleRemoveConversation = (conversation: any) => {
    const isGroup = conversation.guid;

    if (isGroup) handleLeaveGroup(conversation);
    else deleteConversation(conversation);
  };

  const handleBlockUser = async (uid: string) => {
    try {
      const isBlocked = blockedUsers.some(user => user.getUid() === uid);

      if (isBlocked) {
        // Unblock user
        await CometChat.unblockUsers([uid]);
        setAlert({ message: 'Gebruiker gedeblokkeerd', type: 'success' });
      } else {
        const hasSubscription = checkUserHasSubscription(loggedInCometChatUser?.getRole() ?? "");
        if (!hasSubscription) {
          setNeedSubscriptionWarning(
            <div className="alert alert-warning" role="alert">
              Je moet een premium abonnement hebben om deze gebruiker te blokkeren.
            </div>
          );
          return null;
        }

        // Block user
        await CometChat.blockUsers([uid]);
        setAlert({ message: 'Gebruiker geblokkeerd', type: 'success' });
      }
      // Fetch the updated list of blocked users
      fetchBlockedUsers();
    } catch (error) {
      console.error(`Error blocking/unblocking user ${uid}:`, error);
    }
  };

  const hasSentFriendRequest = (user: CometChat.User) => {
    return outgoingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const hasReceivedFriendRequest = (user: CometChat.User) => {
    return incomingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const handleRemoveFriend = async (friend: CometChat.User) => {
    if (!loggedInCometChatUser) return;

    await removeFriend(functions, friend);
    setAlert({ message: 'Vriend verwijderd', type: 'success' });
    loadFriends();
  };

  const handleAcceptFriendRequest = async (request: FriendRequest) => {
    if (!loggedInCometChatUser) return;

    await acceptFriendRequest(functions, request);
    setAlert({ message: 'Vriendschapsverzoek geaccepteerd', type: 'success' });
    loadIncomingFriendRequests();
    loadFriends();
  };

  const handleFriendRequest = async (user: CometChat.User) => {
    // Can only send friend requests if logged in
    if (!loggedInCometChatUser) return;

    try {
      await sendFriendRequest(functions, user.getUid());
      setAlert({ message: 'Vriendschapsverzoek verstuurd', type: 'success' });

      // Reload the list of outgoing friend requests
      loadOutgoingFriendRequests();
    } catch (error) {
      console.error("Error sending friend request:", error);
      setAlert({ message: 'Er is iets misgegaan bij het versturen van het vriendschapsverzoek. Probeer het later opnieuw.', type: 'error' });
    }
  };

  const handleAddOrRemoveFriend = async (user: CometChat.User) => {
    try {
      if (friends.some(friend => friend.getUid() === user.getUid())) {
        // Remove friend
        await handleRemoveFriend(user);
      } else if (hasReceivedFriendRequest(user)) {
        const friendRequest = incomingFriendRequests.find(friendRequest => friendRequest.getUid() === user.getUid());
        if (!friendRequest) return;
        handleAcceptFriendRequest(friendRequest);
      } else {
        // Send friend request
        await handleFriendRequest(user);
      }
    } catch (error) {
      console.error("Error adding/removing friend:", error);
    }
  };

  const handleUnMuteUser = async (user: CometChat.User) => {
    const unmuteUser = httpsCallable(functions, 'unMuteUser');

    try {
      await unmuteUser({ uid: user.getUid() });
      setAlert({ message: `Gebruiker ${user.getName()} is geunmute`, type: 'success' });
      fetchGroupMembers(currentChat);
    } catch (e) {
      console.error("Error unmuting user:", e);
      setAlert({ message: `Er is iets misgegaan bij het unmuten van ${user.getName()}`, type: 'error' });
    }
  }

  const handleMuteUser = async (user: CometChat.User, unMute?: boolean) => {
    if (unMute) {
      handleUnMuteUser(user);
      return;
    }

    const muteUser = httpsCallable(functions, 'muteUser');

    try {
      await muteUser({ uid: user.getUid() });
      setAlert({ message: `Gebruiker ${user.getName()} is gemute`, type: 'success' });

      fetchGroupMembers(currentChat);
    } catch (e) {
      console.error("Error kicking user:", e);
      setAlert({ message: `Er is iets misgegaan bij het kicken van ${user.getName()}`, type: 'error' });
    }
  }

  const handleKickUser = async (user: CometChat.User, group: CometChat.Group) => {
    const kickUser = httpsCallable(functions, 'kickUser');

    try {
      await kickUser({ uid: user.getUid(), groupName: group.getName(), guid: group.getGuid() });
      setAlert({ message: `Gebruiker ${user.getName()} is gekicked`, type: 'success' });

    } catch (e) {
      console.error("Error kicking user:", e);
      setAlert({ message: `Er is iets misgegaan bij het kicken van ${user.getName()}`, type: 'error' });
    }
  }

  const handleLogoutKickUser = async (user: CometChat.User) => {
    const logoutKickUser = httpsCallable(functions, 'logoutKickUser');

    try {
      await logoutKickUser({ uid: user.getUid() });
      setAlert({ message: `Gebruiker ${user.getName()} is uitgelogd`, type: 'success' });

    } catch (e) {
      console.error("Error kicking user:", e);
      setAlert({ message: `Er is iets misgegaan bij het kicken van ${user.getName()}`, type: 'error' });
    }
  }

  const handleMakeUserAdmin = async (user: CometChat.User) => {
    const makeUserAdmin = httpsCallable(functions, 'makeUserAdmin');

    try {
      await makeUserAdmin({ uid: user.getUid() });
      setAlert({ message: `Gebruiker ${user.getName()} is nu admin`, type: 'success' });
    } catch {
      setAlert({ message: `Er is iets misgegaan bij het promoveren van ${user.getName()}`, type: 'error' });
    }
  }

  const handleLeaveChannel = (channel: ActiveChannelInterface) => {
    if (channel.isGroup && channel.isOpen) {
      CometChat.leaveGroup(channel.id).catch(error => {
        console.error("Group leaving failed with exception:", error);
      });
    }

    setActiveChannels(prev => {
      const updatedChannels = prev.filter(c => c.id !== channel.id);
      return updatedChannels.sort((a, b) => a.name.localeCompare(b.name));
    });

    if (currentChat.id === channel.id) {
      setCurrentChat({ id: '', icon: '', name: '', joinedAt: 0, isGroup: false });
    }

    // Remove the notifications about the left channel
    const { [channel.id]: _, ...rest } = unreadCount;
    setUnreadCount(rest);
  };

  // Listen to group members join and leave events and update the members list of the channel
  const listenToGroupMembers = (currentChat: ActiveChannelInterface, members: CometChat.GroupMember[]) => {
    const groupListenerId = 'group-members-listener';

    // Remove previous listener
    CometChat.removeGroupListener(groupListenerId);
    // Add group listener to handle member join and leave events
    CometChat.addGroupListener(
      groupListenerId,
      new CometChat.GroupListener({
        onMemberAddedToGroup: (message: CometChat.BaseMessage, userAdded: CometChat.User, userAddedBy: any, userAddedIn: CometChat.Group) => {
          if (userAddedIn.getGuid() === currentChat.id) {
            // Add the new member to the members list
            const newMember = new CometChat.GroupMember(userAdded.getUid(), 'participant');
            newMember.setAvatar(userAdded.getAvatar());
            newMember.setBlockedByMe(userAdded.getBlockedByMe());
            newMember.setName(userAdded.getName());
            newMember.setStatus(userAdded.getStatus());
            newMember.setMetadata(userAdded.getMetadata());
            newMember.setRole(userAdded.getRole());

            const newMembers = [...members, newMember];
            setMembers(newMembers);

            if (message.getCategory() === 'action' && !showLoadMostRecentMsgBtn) {
              setMessages(prevMessages => [...prevMessages, message]);
              // Set message to read
              CometChat.markAsRead(message);
            }
          }
        },
        onGroupMemberJoined: (_: CometChat.Action, joinedUser: CometChat.User, joinedGroup: any) => {
          if (joinedGroup.getGuid() === currentChat.id) {
            // Return if the user is already in the members list
            if (members.some(member => member.getUid() === joinedUser.getUid())) return;

            const newMember = new CometChat.GroupMember(joinedUser.getUid(), 'participant');
            newMember.setAvatar(joinedUser.getAvatar());
            newMember.setBlockedByMe(joinedUser.getBlockedByMe());
            newMember.setName(joinedUser.getName());
            newMember.setStatus('online');
            newMember.setMetadata(joinedUser.getMetadata());
            newMember.setRole(joinedUser.getRole());

            const newMembers = [...members, newMember];
            setMembers(newMembers);
          }
        },
        onGroupMemberLeft: (message: CometChat.Action, leftUser: CometChat.User, leftGroup: CometChat.Group) => {
          if (leftGroup.getGuid() === currentChat.id) {
            // Remove the member from the members list
            setMembers(members.filter(member => member.getUid() !== leftUser.getUid()));

            if (!currentChat.isOpen && !showLoadMostRecentMsgBtn) {
              // Display that the user left (not visible in public channels)
              setMessages(prevMessages => [...prevMessages, message]);
              // Set message to read
              CometChat.markAsRead(message);
            }

            // If the member is the logged in user, leave the channel
            if (leftUser.getUid() === loggedInCometChatUser?.getUid()) {
              handleLeaveChannel(currentChat);
            }

            // Remove the user from typing users
            if (usersTyping) {
              setUsersTyping(usersTyping.filter(user => user.name !== leftUser.getName()));
            }
          }
        },
        onGroupMemberKicked: (message: CometChat.Action, kickedUser: CometChat.User, kickedBy: CometChat.User, kickedFrom: CometChat.Group) => {
          if (kickedFrom.getGuid() === currentChat.id) {
            // Remove the kicked user from the members list
            setMembers(members.filter(member => member.getUid() !== kickedUser.getUid()));

            if (!currentChat.isOpen && !showLoadMostRecentMsgBtn) {
              // Display that the user was kicked (not visible in public channels)
              setMessages(prevMessages => [...prevMessages, message]);
              // Set message to read
              CometChat.markAsRead(message);
            }

            // Remove the user from typing users
            if (usersTyping) {
              setUsersTyping(usersTyping.filter(user => user.name !== kickedUser.getName()));
            }
          }
        },
      })
    );
  }

  useEffect(() => {
    listenToGroupMembers(currentChat, members);
  }, [currentChat, members]);

  if (!loggedInUser?.firebaseUser || !loggedInCometChatUser || isLoading) {
    !loggedInCometChatUser && navigate('/login');
    return <LoadingPage />;
  } else if (loggedInCometChatUser) {
    // Redirect to registration step 2 if the user hasn't set their birthdate yet
    const metadata = getProfileFromMetadata(loggedInCometChatUser);
    if (metadata.about.birthDate === 'unknown') navigate('/register-step-2');
  }

  return (
    <section className={`chat-page ${userSettings.generalFontSize} font-${userSettings.font ?? ""}`}>
      <Header
        loggedInUser={loggedInCometChatUser}
        metrics={metrics}
        friends={friends}
        notifications={notifications}
        handleLogout={handleLogout}
        setNotifications={setNotifications}
        unreadCount={unreadCount}
        conversations={conversations}
        handleChangeConversation={handleChangeConversation}
        handleChangeGroupConversation={handleChangeGroupConversation}
        setActiveTabIndex={setActiveTabIndex}
        setCurrentChat={setCurrentChat}
        userSettings={userSettings}
        setAlert={setAlert}
        functions={functions}

        ongoingCall={ongoingCall}
        setOngoingCall={setOngoingCall}
        outgoingCall={outgoingCall}
        setOutgoingCall={setOutgoingCall}

        needSubscriptionWarning={needSubscriptionWarning}
        setNeedSubscriptionWarning={setNeedSubscriptionWarning}
      />
      <div id="call-container" />

      <div className="chat-body">
        <ConversationSDK
          setNotifications={setNotifications}
          unreadCount={unreadCount}
          setUnreadCount={setUnreadCount}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          activeChannels={activeChannels}
          onlineMembersCount={onlineMembersCount}
          handleLeaveChannel={handleLeaveChannel}

          handleChangeConversation={handleChangeConversation}
          handleGroupConversationClick={handleChangeGroupConversation}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          loggedInUser={loggedInCometChatUser}
          firebaseUser={loggedInUser.firebaseUser}

          conversations={conversations}
          setConversations={setConversations}
          initialConversation={initialConversation}
          setInitialConversation={setInitialConversation}
          refreshConversations={refreshConversations}

          incomingFriendRequests={incomingFriendRequests}
          loadIncomingFriendRequests={loadIncomingFriendRequests}
          outgoingFriendRequests={outgoingFriendRequests}
          loadOutgoingFriendRequests={loadOutgoingFriendRequests}
          blockedUsers={blockedUsers}
          handleBlockUser={handleBlockUser}
          friends={friends}
          loadFriends={loadFriends}
          setFriends={setFriends}
          handleRemoveFriend={handleRemoveFriend}
          handleAcceptFriendRequest={handleAcceptFriendRequest}
          handleFriendRequest={handleFriendRequest}
          hasSentFriendRequest={hasSentFriendRequest}
          hasReceivedFriendRequest={hasReceivedFriendRequest}
          handleAddOrRemoveFriend={handleAddOrRemoveFriend}
          handleMuteUser={handleMuteUser}
          handleKickUser={handleKickUser}
          handleLogoutKickUser={handleLogoutKickUser}
          handleMakeUserAdmin={handleMakeUserAdmin}

          members={members}
          setMembers={setMembers}
          chatWithUser={chatWithUser}
          setChatWithUser={setChatWithUser}
          channels={channels}
          setAlert={setAlert}

          messages={messages}
          setMessages={setMessages}
          userSettings={userSettings}
          setUserSettings={setUserSettings}

          showLoadMostRecentMsgBtn={showLoadMostRecentMsgBtn}
          setShowLoadMostRecentMsgBtn={setShowLoadMostRecentMsgBtn}

          functions={functions}
          payFunctions={payFunctions}

          handleLogout={handleLogout}

          handleChangeChannel={handleChangeChannel}
          fetchGroupMembers={fetchGroupMembers}

          handleRemoveConversation={handleRemoveConversation}

          setWarningMessage={setWarningMessage}
          user={user}

          usersTyping={usersTyping}
          setUsersTyping={setUsersTyping}

          ongoingCall={ongoingCall}
          setOngoingCall={setOngoingCall}
          outgoingCall={outgoingCall}
          setOutgoingCall={setOutgoingCall}

          handleInitiateCall={handleInitiateCall}
          handleTerminateCall={handleTerminateCall}

          needSubscriptionWarning={needSubscriptionWarning}
          setNeedSubscriptionWarning={setNeedSubscriptionWarning}
        />
      </div>
    </section>
  );
}
