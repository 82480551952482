import ChannelHeader from 'anchor-ui/channel-header';
import Button from 'anchor-ui/button';
import { IconHome, IconSettings } from 'anchor-ui/icons';
import { getProfileFromMetadata, toggleUserPrivacy, updateUserMetadata } from '../../utils/updateMetadata';
import { metadataInterface } from './../../interfaces/metadataInterface';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { setActiveColor } from '../../utils/activeColor';
import './styles.css';
import ThemePage from './ThemePage';
import PrivacyPage from './PrivacyPage';
import AccountPage from './AccountPage';
import BlockedUsersPage from './BlockedUsersPage';
import { useState } from 'react';
import LeftSettingsMenu from './LeftSettingsMenu';
import Menu from 'anchor-ui/menu';
import { Functions } from 'firebase/functions';
import VisualsPage from './VisualsPage';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { User } from 'firebase/auth';

const SettingsPage = ({
  setCurrentChat,
  loggedInUser,
  setAlert,

  userSettings,
  setUserSettings,

  blockedUsers,
  handleBlockUser,
  functions,
  handleLogout,
  user,
  setNeedSubscriptionWarning,
}: {
  setCurrentChat: (chat: any) => void;
  loggedInUser: CometChat.User;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;

  userSettings: UserSettingsInterface;
  setUserSettings: (userSettings: UserSettingsInterface) => void;

  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  functions: Functions;
  handleLogout: () => void;
  user: User | null;
  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {
  const [userProfile, setUserProfile] = useState(getProfileFromMetadata(loggedInUser));
  const [activePage, setActivePage] = useState('account');

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);

  const handleNavigateHome = () => {
    setCurrentChat({ id: '', icon: '', name: '', isGroup: false });
  };

  // Save changes to the user's metadata
  const handleUpdateUserMetadata = async (newMetadata: metadataInterface) => {
    const updatedMetadata = await updateUserMetadata(functions, newMetadata, loggedInUser);

    if (updatedMetadata && typeof updatedMetadata === 'object') {
      setUserProfile(updatedMetadata);
      setUserSettings({ ...userSettings, ...updatedMetadata.settings });

      setAlert({ message: 'Instellingen zijn bijgewerkt', type: 'success' });
      return true;
    } else {
      setAlert({ message: 'Er is iets misgegaan bij het updaten van je profiel', type: 'error' });
      return false;
    }
  };

  const handleToggleVisiblity = async (visibility: boolean) => {
    try {
      await toggleUserPrivacy(functions, loggedInUser, visibility);
      setUserSettings({ ...userSettings, profileIsPrivate: visibility });

      const newMetadata: metadataInterface = {
        about: {
          gender: userProfile.about.gender,
        },
        settings: {
          ...userSettings,
          profileIsPrivate: visibility,
        },
      };

      loggedInUser.setMetadata(newMetadata);
      await CometChat.updateCurrentUserDetails(loggedInUser);

      setAlert({ message: 'Privacy instellingen zijn bijgewerkt', type: 'success' });
    } catch (error) {
      setAlert({ message: 'Er is iets misgegaan bij het updaten van je privacy instellingen', type: 'error' });
    }
  }

  return (
    <section className="white-page-popover settings-page">

      <Menu
        closeMenu={() => setLeftMenuOpen(!leftMenuOpen)}
        open={leftMenuOpen}
        style={{ backgroundColor: 'transparent' }}
      >
        <LeftSettingsMenu
          activeColorState={userSettings.themeColor}
          activePage={activePage}
          setActivePage={setActivePage}
          isSideNav
          handleClose={() => setLeftMenuOpen(false)}
        />
      </Menu>

      <LeftSettingsMenu
        activeColorState={userSettings.themeColor}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      <div className='settings-body'>
        <ChannelHeader
          name="Instellingen"
          leftButton={
            <Button
              iconButton
              className='button-toggle-menu'
              onClick={() => setLeftMenuOpen(true)}
            >
              <IconSettings
                color={userSettings.themeColor}
              />
            </Button>
          }
          rightButton={
            <Button iconButton onClick={handleNavigateHome}>
              <IconHome />
            </Button>
          }
        />

        <section className='settings-content'>
          {activePage === 'account' ? (
            <AccountPage
              loggedInUser={loggedInUser}
              setAlert={setAlert}
              functions={functions}
              handleLogout={handleLogout}
              user={user}
            />
          ) :
            activePage === 'privacy' ? (
              <PrivacyPage
                isPrivate={userSettings.profileIsPrivate}
                changeVisiblity={(visibility) => handleToggleVisiblity(visibility)}
                userProfile={userProfile}
                handleUpdateUserMetadata={handleUpdateUserMetadata}
              />
            ) :
              activePage === 'themes' ? (
                <ThemePage
                  activeColorState={userSettings.themeColor}
                  handleSetActiveColor={(color) => handleUpdateUserMetadata({ ...userProfile, settings: { themeColor: setActiveColor(color) } })}
                />
              ) :
                activePage === 'visuals' ? (
                  <VisualsPage
                    loggedInUser={loggedInUser}
                    userSettings={userSettings}
                    setUserSettings={(settings: UserSettingsInterface) => handleUpdateUserMetadata({ ...userProfile, settings })}
                    setNeedSubscriptionWarning={setNeedSubscriptionWarning}
                    />
                ) : (
                  <BlockedUsersPage
                    blockedUsers={blockedUsers}
                    handleBlockUser={handleBlockUser}
                  />
                )
          }
        </section>

      </div>
    </section>
  );
};

export default SettingsPage;